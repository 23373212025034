$enable-dark-mode: false;
$enable-rounded: false;
$enable-gradients: false;
$enable-validation-icons: true;
$enable-negative-margins: true;

//colors

$primary:       #FF9000;
$secondary:     #0F0F0F;
$dark:          #000;

//@import "mixins/banner";
//@include bsBanner("");


// scss-docs-start import-stack
// Configuration
@import "functions";
@import "variables";
//@import "variables-dark";
@import "maps";
@import "mixins";
@import "utilities";

//Options
$input-padding-y-lg: 0.75rem;

$input-color: $white;
$input-bg: transparent;
$input-border-color: $white;
$input-focus-bg: $input-bg;
$input-focus-color: $white;
$input-padding-y-lg: $input-padding-y-lg;
$input-placeholder-color: #888;

$form-select-padding-y-lg: $input-padding-y-lg;
$form-select-bg: $input-bg;
$form-select-color: $input-color;


// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
//@import "dropdown";
@import "button-group";
//@import "nav";
//@import "navbar";
//@import "card";
//@import "accordion";
//@import "breadcrumb";
@import "pagination";
@import "badge";
@import "alert";
@import "progress";
//@import "list-group";
@import "close";
@import "toasts";
@import "modal";
//@import "tooltip";
//@import "popover";
//@import "carousel";
@import "spinners";
@import "offcanvas";
@import "placeholders";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack